import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2vw",
    display: "flex",
    flexDirection: "column",
    minWidth: "40vw",

    // marginLeft: "80px",
  },
  
}));
function AdminProfile(props) {
    const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant="h1"
        style={{ fontFamily: "Poppins", fontWeight: "400" }}>
        {props.name}
      </Typography>
    </div>
  );
}

export default AdminProfile