import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Typography, Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "79%",
    height: "100px",
    backgroundColor: "#111111",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
function SelectedUsers(props) {
  const rejectUsers = () => {
    console.log("reject");
  };
  const acceptUsers = () => {
    console.log("accept");
  };
  const { selectedRows } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5">
        Selected Users : {selectedRows.length}
      </Typography>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button variant="contained" color="primary" onClick={acceptUsers}>
          Accept
        </Button>
        <Button variant="contained" color="secondary" onClick={rejectUsers}>
          Reject
        </Button>
      </div>
    </div>
  );
}

export default SelectedUsers;
