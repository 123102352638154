import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request } from "../../utils/instance";
import { mockRequest } from "../../utils/mockInstance";
import { TableInstance } from "./TableInstance";

export const TableQuery = ({ filter }) => {
  const {
    data: apiResponse,
    isLoading,
    refetch,
  } = useQuery("new-users", () => {
    return request({
      url: "/api/user/admin",
      method: "GET",
    });
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    apiResponse?.data && (
      <>
        <TableInstance
          tableData={apiResponse.data}
          refetch={refetch}
          condition={filter}
        />
      </>
    )
  );
};

