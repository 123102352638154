import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "29vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    maxWidth: "100vw",

  },
}));
function HomePageCard(props) {
  const classes = useStyles();
  return (
    <div style={{ padding: "10px" }}>
      <div
        className={classes.root}
        style={{
          backgroundImage: `linear-gradient(180deg,rgba(8, 8, 8,0.4),rgba(8, 8, 8,0.4)), url(${props.item.image})`,
          filter: "contrast(110%)",
          backgroundSize: "cover",

        }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundImage: `linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,66,92,0) 56%, ${props.item.color} 100%)`,
            backgroundSize: "cover",
            textAlign: "center",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

          }}>
          <Typography variant="h3" component={"h3"}>
            {props.item.title}
          </Typography>
        </div>
      </div>
    </div>
  );
}

HomePageCard.propTypes = {
  item: PropTypes.object,
};

export default HomePageCard;
