import { Switch } from "react-router-dom";
import Home from "../pages/Home";
import Sessions from "../pages/Sessions";
import CourseOutline from "../pages/CourseOutline";
import UserProfile from "../pages/UserProfile";
import ViewPlans from "../pages/ViewPlans";
import PublicRoute from "./public";
// import PrivateRoute from "./restricted";
import InvalidPage from "../pages/InvalidPage";

import AdminDashboard from "../pages/Admin/AdminDashboard";
import AdminRoutes from "./admin";
import AdminLogin from "../pages/Admin/AdminLogin";
import PrivateRoute from "./restricted";
import AdminProfile from "../pages/Admin/AdminProfile";
import { routesContants } from "../utils/constants";
// import DataTable from "../Components/Admin/DataTable";
export const filters = {
  EXISTING_I: "existing_instructors",
  EXISTING_S: "existing_students",
  NEW_I: "waitlisted",
};
function Routes() {
  const adminRoutes = {
    admin: "Admin Dashboard",
    instructor: "Instructors",
    student: "Students",
    existing_instructors: "Existing Instructors",
    existing_students: "Existing Students",
  };

  return (
    <>
      <Switch>
        <PublicRoute restricted={false} path="/" exact Comp={Home} />
        <AdminRoutes
          restricted={false}
          path={routesContants.ADMIN}
          exact
          Comp={() => {
            return <AdminProfile name={adminRoutes.admin} />;
          }}
        />
        <AdminRoutes
          restricted={false}
          exact
          path={routesContants.ADMIN_NEW_INSTRUCTOR}
          Comp={() => {
            return (
              <AdminDashboard
                name={adminRoutes.instructor}
                users={"/instructors"}
                filter={filters.NEW_I}
              />
            );
          }}
        />
        <AdminRoutes
          restricted={false}
          exact
          path={routesContants.ADMIN_EXISTING_INSTRUCTOR}
          Comp={() => {
            return (
              <AdminDashboard
                name={adminRoutes.existing_instructors}
                users={"/instructors"}
                filter={filters.EXISTING_I}
              />
            );
          }}
        />
        <AdminRoutes
          restricted={false}
          exact
          path={routesContants.ADMIN_EXISTING_STUDENT}
          Comp={() => {
            return (
              <AdminDashboard
                name={adminRoutes.existing_students}
                users={"/students"}
                filter={filters.EXISTING_S}
              />
            );
          }}
        />
        <PublicRoute
          restricted={false}
          path={routesContants.ADMIN_LOGIN}
          Comp={AdminLogin}
        />
        <PublicRoute
          restricted={false}
          path={routesContants.SESSIONS}
          Comp={Sessions}
        />
        <PrivateRoute
          restricted={false}
          path={routesContants.MY_COURSES}
          Comp={UserProfile}
        />
        <PublicRoute
          restricted={false}
          path={routesContants.COURSE}
          Comp={CourseOutline}
        />
        <PublicRoute
          restricted={false}
          path={routesContants.COURSE_OUTLINE}
          Comp={CourseOutline}
        />
        <PrivateRoute
          restricted={false}
          path={routesContants.USER_PROFILE}
          Comp={UserProfile}
        />
        <PublicRoute
          restricted={false}
          path={routesContants.PLANS}
          Comp={ViewPlans}
        />

        <PublicRoute
          restricted={false}
          path={routesContants.INVALID}
          Comp={InvalidPage}
        />
      </Switch>
    </>
  );
}
export default Routes;
//TODO: Implement the routes using the react-query library.

