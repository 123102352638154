import { Button } from "@material-ui/core";
import { useMemo } from "react";

import { useRowSelect, useTable } from "react-table";
import getApi from "../../utils/apis";
import { request } from "../../utils/instance";
import { CheckBoxTable } from "./CheckBox";
import { TableLayout } from "./TableLayout";

export const TableInstance = ({ tableData, refetch, condition }) => {
  //With access to our tableData, we create and array-destructure two new variables called columns and data

  const [columns, data] = useMemo(() => {
    const onUserAction = async (id, action) => {
      try {
        request({
          url: getApi(`api/user/admin/id/${id}`),
          method: "PUT",
          data: {
            userStatus: action,
          },
        });
      } catch (error) {
        console.log(error);
      }
    };
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "User Status",
        accessor: "userStatus",
        Cell: ({ value }) => {
          console.log(value);
          return value;
        },
      },
      {
        Header: "User Actions",
        accessor: "userActions",
        Cell: (row) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              {row.row.original.userStatus === "active" ? (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#ffca00", color: "black" }}
                  onClick={async () => {
                    onUserAction(row.row.original.id, "waitlisted");
                    console.log(row.row.original);
                    window.location.reload();
                  }}>
                  Add to Waitlist
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#28b67e", color: "black" }}
                  onClick={async () => {
                    onUserAction(row.row.original.id, "active");
                    console.log(row.row.original);
                    window.location.reload();
                  }}>
                  Accept
                </Button>
              )}
            </div>
          );
        },
      },
    ];
    return [
      columns,
      tableData.data.data.rows.filter((row) => {
        
        if (condition === "existing_instructors")
          return (
            row.roles.includes("instructor") && row.userStatus === "active"
          );
        else if (condition === "existing_students")
          return (
            row.roles.includes("user") &&
            row.roles.length === 1 &&
            row.userStatus === "active"
          );
        else if (condition === "waitlisted")
          return row.userStatus === "waitlisted";
        return row;
      }),
    ];
  }, [condition, tableData.data.data.rows]);

  const tableInstance = useTable({ columns, data }, useRowSelect);
  // return <>{console.log()}</>;
  return <TableLayout {...tableInstance} />;
};
