export const routesContants = {
    INVALID : '*',
    HOME : '/',
    ADMIN : '/admin',
    ADMIN_LOGIN : '/adminlog',
    ADMIN_NEW_INSTRUCTOR : '/admin/new/instructors',
    ADMIN_NEW_STUDENT : '/admin/new/students',
    ADMIN_EXISTING_INSTRUCTOR : '/admin/existing/instructors',
    ADMIN_EXISTING_STUDENT : '/admin/existing/students',
    SESSIONS: '/sessions',
    MY_COURSES: '/myCourses',
    COURSE: '/course',
    COURSE_OUTLINE: '/course/:id',
    USER_PROFILE: '/userProfile',
    PLANS: '/plans',
}