import React from "react";
import PropTypes from "prop-types";
import { Avatar, makeStyles, Typography } from "@material-ui/core";

import user from "../../images/user.jpg";
import { Link, NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { routesContants } from "../../utils/constants";
// @ts-ignore
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    gridTemplateRows: "1fr",
    gridColumnGap: "0px",
    gridRowGap: "0px",
    minHeight: "100vh",
    
    // maxHeight:"100vh",
    // overflowY:"hidden",
    backgroundColor:"#111111"
  },
  sidebar: {
    gridArea: "1 / 1 / 2 / 2",
    borderRight: "1px solid #2E2E2E",
    // padding:"20px",
    width: "auto",
    boxSizing: "border-box",
    
    
    top:"0"

  },
  user: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    // justifyContent: "center",
    paddingLeft: "2vw",
    padding: "30px 0",
    borderBottom: "1px solid #2E2E2E",
  },
  main: {
    gridArea: "1 / 2 / 2 / 3",
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  newUser: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",

    borderBottom: "1px solid #2E2E2E",
  },
  existingUser: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",
  },
  newUserContainer: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "2vw",
    gap: "8px",
    color: "#7a7a7a",
    alignItems: "center",
  },
  navLinks: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    textDecoration: "none",
    paddingTop: "20px",
    color: "white",
  },
  navigation: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1.5vw",
  },
  icon: {
    fontSize: "32px",
  },
}));
function AdminLayout(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AdminNavbar/>
      <div className={classes.main}>{props.children}</div>
    </div>
  );
}

export default AdminLayout;

function AdminNavbar(){
  const classes = useStyles();
  return (
    <div className={classes.sidebar}>
      <div className={classes.user}>
        <Avatar src={user} variant="rounded" className={classes.avatar} />
        <div>
          <Typography
            variant="h6"
            style={{ lineHeight: "25px", fontWeight: "bold" }}>
            {" "}
            Welcome User{" "}
          </Typography>
          <Typography variant="subtitle1">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={routesContants.HOME}>
              {" "}
              Logout{" "}
            </Link>
          </Typography>
        </div>
      </div>

      <div className={classes.newUser}>
        <div className={classes.newUserContainer}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: "16px",
              color: "#7a7a7a",
              fontFamily: "Poppins",
              marginBottom: "10px",
            }}>
            NEW USERS
          </Typography>
        </div>
        <div className={classes.navigation}>
          <SidebarNavigations
            // @ts-ignore
            Icon={KeyboardArrowRightIcon}
            name="Instructors"
            link={routesContants.ADMIN_NEW_INSTRUCTOR}
          />
        </div>
      </div>
      <div className={classes.existingUser}>
        <div className={classes.newUserContainer}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: "16px",
              color: "#7a7a7a",
              fontFamily: "Poppins",
              marginBottom: "10px",
            }}>
            EXISTING USERS
          </Typography>
        </div>
        <div className={classes.navigation}>
          <SidebarNavigations
            // @ts-ignore
            Icon={KeyboardArrowRightIcon}
            name="Instructors"
            link={routesContants.ADMIN_EXISTING_INSTRUCTOR}
          />
          <SidebarNavigations
            // @ts-ignore
            Icon={KeyboardArrowRightIcon}
            name="Students"
            link={routesContants.ADMIN_EXISTING_STUDENT}
          />
          <SidebarNavigations
            // @ts-ignore
            Icon={KeyboardArrowRightIcon}
            name="Courses"
            link="/admin"
          />
        </div>
      </div>
    </div>
  );
}
function SidebarNavigations({ Icon, name, link }) {
  const classes = useStyles();
  return (
    <>
      {Icon && name ? (
        <NavLink to={link} className={classes.navLinks}>
        {/*<Icon className={classes.icon}/>*/}
          <Typography variant="h6" style={{fontSize:"20px", fontFamily:"Poppins", textTransform:"uppercase",  paddingLeft: ".5vw",}}>{name}</Typography>
        </NavLink>
      ) : null}
    </>
  );
}


AdminNavbar.propTypes = {
  children: PropTypes.element,
};

SidebarNavigations.propTypes = {
  Icon: PropTypes.element,
name: PropTypes.string,
  link: PropTypes.string,
};
