import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import img2 from "../images/courseThumbnail.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "12em",
    // width: "auto",
    background: "white",
    borderRadius: "10px",
    // "backgroundImage": `linear-gradient(rgba(
    //     0, 0, 0, 0.78), rgba(0, 0, 0, 0.78)), url(${img2})`,
    backgroundColor: "black",
    backgroundSize: "cover",
    border: "2px solid #FF416C",
    padding: "25px",
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px",
    flexDirection: "column",
    "&:hover": {
      border: "2px solid #e5e5e5",
    },
  },
  text: {
    marginTop: "8x",
  },
  heading: {
    fontWeight: "600",
    color: "#FF4364",
  },
}));

function MyCourseCard({ course }) {
  const classes = useStyles();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Box className={classes.root} style={isXs ? { margin: "10px 0" } : null}>
        <Typography
          variant="h5"
          component={"h5"}
          className={clsx(classes.text, classes.heading)}>
          {course.name}
        </Typography>
        <Typography
          variant="subtitle2"
          component={"p"}
          className={classes.text}>
          Lobortis accumsan stet nonumy ipsum vero elitr clita et justo vel et
          sed ipsum dolor eos no. Iusto justo sit nonumy delenit id sed amet
          vero et sed nulla.
        </Typography>
        <Button style={{ marginTop: "12px" }}>View Course</Button>
      </Box>
    </Grid>
  );
}

export default MyCourseCard;
