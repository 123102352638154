import { Box, makeStyles, Typography } from "@material-ui/core";

import React from "react";
// import DataTable from '../../Components/Admin/DataTable.js'
import PropTypes from "prop-types";

import "./Admin.css";
import { TableQuery } from "../../Components/Admin/TableQuery";
import SelectedUsers from "../../Components/Admin/SelectedUsers";
import useAllusers from "../../hooks/admin/useAllUsers";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2vw",
    display: "flex",
    flexDirection: "column",
    minWidth: "40vw",

    // marginLeft: "80px",
  },
  newUsers: {
    // width: "100%",
    height: "100%",
    padding: "20px",
    background: "#0e0e0e",
    borderRadius: "20px",
    boxSizing: "border-box",
  },
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-between",
    background: "#0b0b0b",
    padding: "15px 15px 15px 35px",
    borderRadius: "10px",
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#fff",
    padding: "10px",
    background: "#0e0e0e",
    margin: "0 5px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      background: "#ff4154",
    },
  },
}));
function AdminDashboard(props) {
  const classes = useStyles();

  return (
    // @ts-ignore
    <Box className={classes.root}>
      {props.name && props.users && (
        <>
          <Typography
            variant="h1"
            style={{ fontFamily: "Poppins", fontWeight: "400" }}>
            {props.name}
          </Typography>

          <TableQuery users={props.users} filter={ props.filter} />
        </>
      )}
    </Box>
  );
}

export default AdminDashboard;


//propTypes for Admin Dashboard
AdminDashboard.propTypes = {
  name: PropTypes.string.isRequired,
  users: PropTypes.string.isRequired,
};