import React from "react";

import {
  Button,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";

import userProfileInput from "../../theme/userProfileInput";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: {
    width: "20vw",
    margin: "auto",
    marginTop: "4vh",
    backgroundColor: "#080808",
    // minHeight: "30vh",
    padding: "4vw 4vh",
    paddingTop: "2vh",
    textAlign: "left",
    color: "white",
  },
  textField: {
    marginTop: "1vh",
  },
  button: {
    marginTop: "2vh",
    padding: "15px",
  },
}));
function AdminLogin() {
  const classes = useStyles();
  const [credentials, setCredentials] = React.useState({
    username: "",
    password: "",
  });

  //a function to create a admin cookie on login
  const history = useHistory();
  const createAdminCookie = () => {
    Cookies.set("admin", "true", { expires: 1 });
    history.replace("/admin");
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">You are not Authorized to Admin</Typography>
      {setTimeout(() => {
        history.replace("/");
      }, 2000)}
    </div>
  );
}

export default AdminLogin;
