import Cookies from "js-cookie";
import React from "react";

import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import AdminLayout from "../Components/Admin/Admin.Layout";
const AdminRoutes = ({ Comp, restricted, ...rest }) => {
  // const jwtVerify = Cookies.get("jwt");
  const { roles } = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : "";

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route

    <Route
      {...rest}
      render={(props) =>
        Cookies.get("admin") === "true" ? (
          <>
            {console.log(Cookies.get("user"))}
            <AdminLayout>
              <Comp {...props} {...rest} />
            </AdminLayout>
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AdminRoutes;
AdminRoutes.prototypes = {
  Comp: PropTypes.elementType.isRequired,
};
