import axios from "axios";


const client = axios.create({
  baseURL:
    "https://my-json-server.typicode.com/symaticvisuals/mock-jsonplaceholder/",
});
export const mockRequest = ({ ...options }) => {
  
  const onSuccess = (response) => {
    return response;
  };
  const onError = (error) => {
    return error;
  };
  return client(options).then(onSuccess).catch(onError);
};
